const routes = {
    home: '/',
    profile: '/profile',
    search: '/search',
    destination: '/destination',
    destination_detail: '/destination/:id',
    tour: '/tour',
    tour_detail: '/tour/:id',
    tour_loved: '/loved',
    blog: '/blog',
    blog_detail: '/blog/:id',
    contact: '/contact',
    about_us: '/about-us',
    team: '/team',
    team_detail: '/team-detail',
    error_404: '*',
    gallery: '/gallery',
    login: '/login',
    payment: '/payment',
    order: '/order',
    order_detail: '/order/:id',
    //Admin
    admin: '/admin',
    admin_account: '/admin-account',
    admin_account_detail: '/admin-account/:id',
    admin_tour: '/admin-tour',
    admin_tour_detail: '/admin-tour/:id',
    admin_tour_creat: '/admin-tour/create',
    admin_destination: '/admin-destination',
    admin_destination_detail: '/admin-destination/:id',
    admin_destination_create: '/admin-destination/create',
    admin_blog: '/admin-blog',
    admin_blog_detail: '/admin-blog/:id',
    admin_blog_create: '/admin-blog/create',
    admin_contact: '/admin-contact',
    admin_contact_detail: '/admin-contact/:id',
    admin_ticket: '/admin-ticket',
    admin_ticket_detail: '/admin-ticket/:id',
    admin_ticket_create: '/admin-ticket/create',
    admin_deals: '/admin-deals',
    admin_deals_detail: '/admin-deals/:id',
    admin_deals_create: '/admin-deals/create',
    admin_company: '/admin-company',
    admin_order: '/admin-order',
    admin_order_detail: '/admin-order/:id',
};

export default routes;
