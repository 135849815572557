import dummyData from './dummyData';
import roles from './roles';
import routes from './routes';

const config = {
    routes,
    roles,
    dummyData,
};

export default config;
